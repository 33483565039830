import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';
import { motion } from 'framer-motion';
import withLocation from '../utils/withLocation';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import SvgLines from 'react-mt-svg-lines';

import MenuIcon from '../images/header/Vector.svg';
import MenuIconDarkMode from '../images/header/menuIconDarkMode.svg';
import Close from '../images/header/close.svg';
import Logo from '../images/header/whiteLogo.png';
import Squiggle from '../images/header/menuSquiggle.svg';

//Logo Import
// import CrimsonWhite from '../images/assets/Logos/crimson_white.png';
import TealWhite from '../images/assets/Logos/teal_white.png';
import VioletWhite from '../images/assets/Logos/violet_white.png';
import CrimsonWhite from '../images/assets/Logos/reload-s360.svg';

import MainMenuLinks from '../components/menu-components/mainMenuLinks';
import LocationDetails from '../components/menu-components/location';
import ContactDetails from '../components/menu-components/contactDetails';
import CaseStudyLinks from '../components/menu-components/caseStudyLinks';

const MenuMasterContainer = styled.div`
  position: relative;
  font-family: Montserrat;
  @media (max-width: 1440px) {
  }
  @media (max-width: 1150px) {
    display: flex;
  }
`;

const MenuContainer = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: hidden;
  background-color: #131313;
  top: 0;
  left: 0;
  z-index: 100000;
  overflow-y: scroll;
`;

const SquiggleOuterContainer = styled.div`
  width: 100%;
  transform-origin: right;
  -webkit-transform-origin-y: bottom;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: -100;
  @media (max-width: 1200px) {
    transform: scale(0.75);
  }
  @media (max-width: 1100px) {
    transform: scale(0.6);
  }
  @media (max-width: 700px) {
    transform: scale(0.5);
  }
  @media (max-width: 500px) {
    transform: scale(0.4);
  }
  @media (max-width: 400px) {
    transform: scale(0.3);
  }
  @media (max-width: 300px) {
    transform: scale(0.3);
  }
  @media (max-width: 200px) {
    transform: scale(0.2);
  }
  @media (max-width: 100px) {
    transform: scale(0.1);
  }
`;

const SquiggleContainer = styled(motion.div)`
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  right: 0;
  width: 100%;
`;

const menuAnim = {
  visible: {
    height: '100vh',
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
  hidden: {
    height: '0vh',
    transition: {
      duration: 0.25,
      delay: 0.3,
      ease: 'easeIn',
    },
  },
};

const MenuIconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 120px;
  justify-content: center;
  margin-right: 50px;

  margin-left: 50px;
  @media (max-width: 1150px) {
    margin-right: 5vw;
    margin-left: 0;
    height: unset;
  }
`;

const CloseButtonContainer = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 60px;
  margin-right: 50px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  @media (max-width: 900px) {
    margin-right: 0;
  }
  @media (min-width: 1600px) {
    margin-right: 0;
  }
`;

const closeAnim = {
  visible: {
    marginTop: '60px',
    transition: {
      duration: 0.4,
      delay: 0.2,
      ease: 'easeOut',
    },
  },
  hidden: {
    marginTop: '-100px',
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
};

const MenuLogoContainer = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 60px;
  margin-left: 50px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  @media (min-width: 1620px) {
    margin-left: 0px;
  }
  @media (max-width: 900px) {
    margin-left: 0;
  }

  svg {
    width: 100px;
    margin-top: -10px;
    height: auto;
  }
`;

const MenuLogo = styled.img`
  width: 100px;
  margin-top: -17.5px;
`;

const MasterMenuContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  align-items: flex-start;
  z-index: 10;
`;

const MenuContentContainer = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    width: 80%;
  }
`;

const MainMenuLinksContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 30px;
  max-width: 1660px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

const MainMenuContentContainerRight = styled.div`
  width: 50%;
  display: flex;
  @media (max-width: 1200px) {
    width: 66.6%;
    justify-content: space-evenly;
  }
  @media (max-width: 900px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const CaseStudyLinksContainer = styled.div`
  min-width: 325px;
  margin-right: 60px;
  @media (max-width: 900px) {
    display: none;
  }
`;

const MenuLogoOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    height: 150px;
  }
  @media (max-width: 500px) {
    height: 100px;
  }
`;

const MenuLogoInnerContainer = styled.div`
  max-width: 1620px;
  height: 120px;
  width: 100%;
  position: relative;
  @media (max-width: 900px) {
    width: 80%;
  }
`;

const DesktopMenuIconContainer = styled.div`
  @media (max-width: 1150px) {
    display: none;
  }
`;

const MobileMenuIconContainer = styled.div`
  display: none;
  @media (max-width: 1150px) {
    display: block;
    margin-right: 10px;
  }
`;

const LocationSocialContainer = styled.div`
  margin-bottom: 25px;
`;

const MenuIconInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
`;

const logoAnim = {
  visible: {
    marginTop: '60px',
    transition: {
      duration: 0.3,
      delay: 0.3,
      ease: 'easeOut',
    },
  },
  hidden: {
    marginTop: '-100px',
    transition: {
      duration: 0.3,
      delay: 0.1,
      ease: 'easeIn',
    },
  },
};

const squiggleAnim = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0,
      delay: 0,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
      menuVisible: false,
      menuUnloading: false,
      timingOut: false,
    };
  }

  handleMenuToggle = () => {
    if (this.state.timingOut) {
      this.setState({
        menuActive: true,
        menuVisible: true,
        menuUnloading: false,
        timingOut: false,
      });
    }
    if (this.state.menuActive) {
      this.setState({
        menuUnloading: true,
        timingOut: true,
      });
      setTimeout(() => {
        this.setState({
          menuActive: false,
        });
      }, 50);
      setTimeout(() => {
        if (this.state.timingOut) {
          this.setState({
            menuVisible: false,
            timingOut: false,
          });
        }
      }, 300);
    } else {
      this.setState({
        menuActive: true,
        menuVisible: true,
        menuUnloading: false,
      });
    }
  };

  handleMenuClose = (passedLink, location) => {
    this.handleMenuToggle();
    if (passedLink !== location) {
      this.props.handleMenuLoading();
      setTimeout(() => {
        navigate(passedLink);
      }, 1000);
    }
  };

  render() {
    return (
      <MenuMasterContainer>
        <DesktopMenuIconContainer>
          <MenuIconContainer>
            <MenuIconInnerContainer onClick={() => this.handleMenuToggle()}>
              <MenuIcon fill="#FFF" />
            </MenuIconInnerContainer>
          </MenuIconContainer>
        </DesktopMenuIconContainer>
        {this.props.mobile_header_items_color &&
        this.props.mobile_header_items_color !== undefined ? (
          <React.Fragment>
            {this.props.mobile_header_items_color === '#ffffff' ? (
              <MobileMenuIconContainer>
                <MenuIconInnerContainer onClick={() => this.handleMenuToggle()}>
                  <MenuIcon fill={'#ffffff'} />
                </MenuIconInnerContainer>
              </MobileMenuIconContainer>
            ) : (
              <MobileMenuIconContainer>
                <MenuIconInnerContainer onClick={() => this.handleMenuToggle()}>
                  {this.props.desktop_logo_colour === 'crimson_white' ? (
                    <MenuIcon fill="#FFF" />
                  ) : this.props.mobile_header_items_color ? (
                    <MenuIcon fill="#FFF" />
                  ) : null}
                </MenuIconInnerContainer>
              </MobileMenuIconContainer>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MobileMenuIconContainer>
              <MenuIconInnerContainer onClick={() => this.handleMenuToggle()}>
                <MenuIcon fill={'#ffffff'} />
              </MenuIconInnerContainer>
            </MobileMenuIconContainer>
          </React.Fragment>
        )}

        <MenuContainer
          variants={menuAnim}
          initial="hidden"
          animate={this.state.menuActive ? 'visible' : 'hidden'}
        >
          <MenuLogoOuterContainer>
            <MenuLogoInnerContainer>
              <MenuLogoContainer
                variants={logoAnim}
                initial="hidden"
                animate={this.state.menuActive ? 'visible' : 'hidden'}
              >
                <CrimsonWhite />
              </MenuLogoContainer>
              <CloseButtonContainer
                onClick={() => this.handleMenuToggle()}
                variants={closeAnim}
                initial="hidden"
                animate={this.state.menuActive ? 'visible' : 'hidden'}
              >
                <Close />
              </CloseButtonContainer>
            </MenuLogoInnerContainer>
          </MenuLogoOuterContainer>
          {this.state.menuVisible ? (
            <MasterMenuContentContainer>
              <MenuContentContainer>
                <MainMenuLinksContainer>
                  <MainMenuLinks
                    data={this.props.data}
                    menuUnloading={this.state.menuUnloading}
                    handleMenuClose={this.handleMenuClose}
                  />
                </MainMenuLinksContainer>
              </MenuContentContainer>
            </MasterMenuContentContainer>
          ) : null}
        </MenuContainer>
      </MenuMasterContainer>
    );
  }
}
export default MainMenu;
